import { store } from '../../Store';
import {getTopic, chapterId, getSingleTopic, getQuestion, getSingleQuestion, submitPaper,getTestDetail, getReport, getComments ,getSubjectHomeWork ,getSubjectHomeWorkDetails ,updateTheFlagQuestions} from './action';

const actions = {
    getTopic: (item) => store.dispatch(getTopic(item)),
    getSubjectHomeWork: (item) => store.dispatch(getSubjectHomeWork(item)),    
    getSubjectHomeWorkDetails: (item) => store.dispatch(getSubjectHomeWorkDetails(item)),    
    chapterId:(data) => store.dispatch(chapterId(data)),
    getSingleTopic:(item) => store.dispatch(getSingleTopic(item)),
    getQuestion : (item) => store.dispatch(getQuestion(item)),
    getSingleQuestion : (item) => store.dispatch(getSingleQuestion(item)),
    submitPaper : (item) => store.dispatch(submitPaper(item)),
    getTestDetail : (item) => store.dispatch(getTestDetail(item)), 
    getReport : (item) => store.dispatch(getReport(item)), 
    getComments : () => store.dispatch(getComments()), 
    updateTheFlagQuestions: (item) => store.dispatch(updateTheFlagQuestions(item)),    

}

export default actions;