import {
    GET_TOPIC,
    GET_SUBJECT_HOMEWORK,
    GET_SUBJECT_HOMEWORK_DETAILS,
    CHAPTER_ID,
    GET_SINGLE_TOPIC,
    GET_QUESTIONS,
    GET_SINGLE_QUESTION,
    SUBMIT_PAPER,
    GET_TEST_DETAIL,
    GET_REPORT,
    GET_COMMENTS,
    UPDTE_FLAG_QUESTIONS
} from './constants';

import { TopicApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getTopic = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getTopic(item);
   
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_TOPIC, payload: result});
            return result;
        } else {
            dispatch({ type: GET_TOPIC, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getSubjectHomeWork = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getSubjectHomeWork(item);
   
    if (result.data) {
        if (result.success) {
            dispatch({
                type: GET_SUBJECT_HOMEWORK,
                payload: result
            });
            return result;
        } else {
            dispatch({
                type: GET_SUBJECT_HOMEWORK,
                payload:null
            });
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getSubjectHomeWorkDetails = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getSubjectHomeWorkDetails(item);
   
    if (result.data) {
        if (result.success) {
            dispatch({
                type: GET_SUBJECT_HOMEWORK_DETAILS,
                payload: result
            });
            return result;
        } else {
            dispatch({
                type: GET_SUBJECT_HOMEWORK_DETAILS,
                payload:null
            });
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getSingleTopic = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getSingleTopic(item);
   
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_SINGLE_TOPIC, payload: result});
            return result;
        } else {
            dispatch({ type: GET_SINGLE_TOPIC, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}


export const chapterId = (data) => async(dispatch, getState) => {
    if(data){
        dispatch({type:CHAPTER_ID,payload:data});
        return data;
    }else{
        return Promise.reject("Api error");
    }
} 

export const getQuestion = (item = {}) => async (dispatch, getState) => {
    
    const result = await TopicApi.getQuestion(item);
    if (result) {
        if (result.success) {
            dispatch({ type: GET_QUESTIONS, payload: result});
            return result;
        } else {
            dispatch({ type: GET_QUESTIONS, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getSingleQuestion = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getSingleQuestion(item);
   
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_SINGLE_QUESTION, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_SINGLE_QUESTION, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const submitPaper = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.submitPaper(item);
   
    if (result) {
        if (result.success) {
            dispatch({ type: SUBMIT_PAPER, payload: result.data});
            return result;
        } else {
            dispatch({ type: SUBMIT_PAPER, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getTestDetail = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getTestDetail(item);
   
    if (result) {
        if (result.success) {
            dispatch({ type: GET_TEST_DETAIL, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_TEST_DETAIL, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getReport = (item = {}) => async (dispatch, getState) => {
    
    const result = await TopicApi.getReport(item);
    if (result) {
        if (result.success) {
            dispatch({ type: GET_REPORT, payload: result});
            return result;
        } else {
            dispatch({ type: GET_REPORT, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getComments = () => async (dispatch, getState) => {
    
    const result = await TopicApi.getComments();
    
    if (result) {
        if (result.success) {
            dispatch({ type: GET_COMMENTS, payload: result});
            return result;
        } else {
            dispatch({ type: GET_COMMENTS, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const updateTheFlagQuestions = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.updateFlagQuestions(item);          
    
        if (result.success === true) {
            dispatch({
                type: UPDTE_FLAG_QUESTIONS,
                payload: result
            });
            return result;

        } else {
            dispatch({
                type: UPDTE_FLAG_QUESTIONS,
                payload:null
            });
            return Promise.reject(result.message);
        }
    
}