import React, { useState, useEffect } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Header, Footer } from "../includes";
import axios from 'axios';
import { API_ENDPOINT } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { NotificationApi } from '../../services/NotificationApi'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DocumentMeta from 'react-document-meta';

const EmailRegx= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const number = /^\d{10}$/;
const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState('');
  const [contactNumberError, setContactNumberError] = useState('');
  const [modelSubjectDescription, setModelSubjectDescription] = useState([]);
  const [modelSubjectTitle, setModelSubjectTitle] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [faqListData, setFaqListData] = useState("");
  const [testmonialdata, setTestmonialdata] = useState('');
  const [classSubject, setClassSubject] = useState('');
  const [staticPagesData, setStaticPagesData] = useState([]);
  const [aboutUs, setAboutUs] = useState('');
  const [elevenPlus, setElevenPlus] = useState('');
  const [workShop, setWorkShop] = useState('');
  const [pricing, setPricing] = useState('');
  const [aboutUsHead, setAboutUsHead] = useState('');
  const [elevenPlusHead, setElevenPlusHead] = useState('');
  const [workShopHead, setWorkShopHead] = useState('');
  const [pricingHead, setPricingHead] = useState('');
  const [aboutUsTitle, setAboutUsTitle] = useState('');
  const [elevenPlusTitle, setElevenPlusTitle] = useState('');
  const [workShopTitle, setWorkShopTitle] = useState('');
  const [pricingTitle, setPricingTitle] = useState('');
  const [rowTestData, setRowTestData] = useState('');
  const [activeLoader, setActiveLoade] = useState(false);
  const [activeModel, setActiveModel] = useState(false);
  const [show, setShow] = useState(false);
  const [showDes, setShowDes] = useState(false);
  const [showVideoStatus, setShowVideoStatus] = useState(false);
  const handleClose = () => setShowDes(false);
  const handleShow = () => setShowDes(true);
  // const EmailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    getAllHomePageData();
  }, []);

  const getAllHomePageData = () => {
    NotificationApi.getAllHomePageData()
      .then((response) => {
        const data = response.data;
        const staticPages = data.staticPages

        for (const page of staticPages) {
          if (page.slug == "about-us") {
            setAboutUs(page.text)
            setAboutUsHead(page.subTitle)
            setAboutUsTitle(page.title)
          }
          if (page.slug == "11") {
            setElevenPlus(page.text)
            setElevenPlusHead(page.subTitle)
            setElevenPlusTitle(page.title)
          }
          if (page.slug == "pricing") {
            setPricing(page.text)
            setPricingHead(page.subTitle)
            setPricingTitle(page.title)
          }
          if (page.slug == "workshops") {
            setWorkShop(page.text)
            setWorkShopHead(page.subTitle)
            setWorkShopTitle(page.title)
          }
        }
        createTestimonialData(data.testimonialList);
        const faq = data.faqList;
        const testimonialUser = data.testimonialList;
        const classSubjectdata = data.classWithSubjectList;

        // users = faq data 
        // classSubject == class data 
        setStaticPagesData(staticPages);
        setFaqListData(faq);
        setTestmonialdata(testimonialUser);

        setClassSubject(classSubjectdata);
      }).catch((err) => {
        setError("Network error");
      })
  }

  const hideModelBox = () => {
    setShow(false);
    setModelSubjectDescription("");
    setModelSubjectTitle("");
  }

  const showVideo = () => {
    setShowVideoStatus(true);
  }

  const hideVideoBox = () => {
    setShowVideoStatus(false);
  }
  

  const handleSelect = (selectedIndex, e) => {
    /*if(selectedIndex == 0){
      document.getElementById("test_sldier_0").style.display = "block";
    }else{
      document.getElementById("test_sldier_0").style.display = "none";
    }*/
  };


  const createTestimonialData = (data) => {
    let createStr = "";
    let i = 0;
    let k = 0;

    for (var j = 0; j <= data.length; j++) {

      createStr += (i == 0 || i % 2 === 0) ? '<div key={' + (i + 1) + '} id="test_sldier_' + (i + 1) + '"  class="row carousel-content">' : "";
      createStr += '<div key={' + (i) + '} class="col-md-6">';
      createStr += '<div class="testimonial-wrapper">';


      if (i == 0) {
        createStr += '<video src="https://api.lpi-tuition.com/videos/testimonial-video.mp4" poster="/assets/img/video-banner.png"  width="100%" height="auto" controls >'
        createStr += '  <source src="https://api.lpi-tuition.com/videos/testimonial-video.mp4" type="video/mp4" />'
        createStr += '  </video>'
      } else {
        //section of content
        let contents = data[k].content;
        let showMore = "";
        if (contents.length > 300) {
          showMore = ' ...  </br> <a href="javascript:void(0)" key=' + i + ' data-parentName="' + data[k].parentTitle + '"  data-childName="' + data[k].childTitle + '"  data-courseName="' + data[k].classTitle + '" class="showReadMoreTest" id="data_' + i + '" data-content="' + contents + '">Show More</a>';
        }
        let content = contents.match(/.{1,300}/g);
        createStr += '<div class="media">'
        createStr += '<div class="media-body">'
        createStr += '<div class="overview testimonial-continers ">'
        createStr += '<div class="name"><b>' + data[k].parentTitle + '</b> </div> <div class="name"><b>Child Name: '+ data[k].childTitle + '</b> </div> <div class="name"><b>Course: '+ data[k].classTitle + '</b> </div>';
        createStr += '<div class="testimonial mt-2">' + content[0] + ' ' + showMore + '</div>';
        createStr += '<div class="star-rating">';
        createStr += '<ul class="list-inline">';
        for (let l = 0; l < parseInt(data[k].noOfStar); l++) {
          createStr += '<li class="list-inline-item"><i class="fa fa-star"></i></li>';
        }

        createStr += '</ul>';
        createStr += '</div>'
        createStr += '</div>'
        createStr += '</div>'
        createStr += '</div>'
        //section end for content
        k = k + 1;
      }
      createStr += '</div>'
      createStr += '</div> ';
      createStr += (i % 2 !== 0) ? '</div>' : "";
      i = i + 1;
    }
    setRowTestData(createStr);
  }

  const updateModelContent = (e) => {
    let subjectId = e.target.getAttribute("data-subjectId");
    let subjectDescription = document.getElementById("descBox_" + subjectId).innerHTML;
    let popSubjectName = document.getElementsByClassName("subject-title-" + subjectId)[0].innerText;
    setModelSubjectDescription(subjectDescription);
    setModelSubjectTitle(popSubjectName);
    setShow(true);
  }

  const handleName = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    setName(inputValue.trimStart());
    if (inputValue === "") {
      setNameError("");
      return true;
    } 
    if (inputValue.length < 3) {
      setNameError("Name not be less than 3 character");
    } else {
      setNameError("");
    }
    setName(inputValue.trimStart());
  }

  const handleContactNumber = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    setContactNumber(inputValue);
    if (inputValue === "") {    
      setContactNumberError("");
      return true;
    }
    if(!/^\d+$/.test(inputValue)){
      setContactNumberError("Enter a valid Mobile Number ");
      return false
    }

    if (inputValue.length < 6 || inputValue.length > 13) {
      setContactNumberError("Enter a valid Mobile Number ");
      return false
    } else if (number.test(/^(\+\d{1,3}[- ]?)?\d{10}$/) && !(number.match(/0{5,}/))) {
      setContactNumberError("Enter a valid mobile number");
      return false
    } else {
      setContactNumberError("")
    }
    setContactNumber(inputValue);
  }

  const handleSubject = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    setSubject(inputValue.trimStart());
  }

  const renderSwitch = (classNames) => {
    let cls = classNames.toLowerCase(classNames);
    switch (cls) {
      case 'ks3':
        return 'ks3';
      case 'gcse':
        return 'gcse';
      case 'ks3 crash course':
        return 'ks3crashcourse';
      default:
        return '';
    }
  }
  
  const handleEmail = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setEmailError("");
      return true;
    } 
    
    if (EmailRegx.test(email) === false) {
      setEmailError("Enter a valid email");
    } else {
      setEmailError("");
    }
    setEmail(inputValue.trimStart());
  }

  const renderDiv = (index) => {
    let htmlStr = (index % 2 !== 0) ? '</div>' : "";
    return htmlStr

  }

  const handleMessage = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    setMessage(inputValue.trimStart());
  }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;

    const contactRegx = /^\d{11}$/;

    if (name === "") {
      error = true;
      setNameError("Name is required");
    } 
    
     if (email === "") {
      error = true;
      setEmailError("Email is required");
    }  
    if (EmailRegx.test(email) === false) {
      error = true;
      setEmailError("Enter a valid email");
    }
      if (subject === "") {
      error = true;
      setSubjectError("Subject is required");
    } 
     if (message === "") {
      error = true;
      setMessageError("Message is required");
    } 
    if (contactNumber.length < 6 || contactNumber.length > 13) {
      error = true;
      setContactNumberError("Contact Number is required")
    }

    if (contactRegx.test(contactNumber) === false) {
      error = true;
      setContactNumberError("Allows 11 digit only.");
    }

    if (error) {
      return false;
    } else {
      setNameError("");
      setEmailError("");
      setMessageError("");
      setSubjectError("");
      setContactNumberError("");

      const data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
        contact_number:contactNumber
      };
      Contact(data);
    }
  }

  const Contact = async (data) => {
    try {
      const response = await axios.put(`${API_ENDPOINT}/inquiry`, JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
      if (response.status === 200) {
        toast.success("Inquiry has been submitted successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("contactForm").reset();
        resetForm();
        return true;

      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return response.data;
      }
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return Promise.reject(error);
    }
  }
  const styles = { height: 400, width: "100%" };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setSubject("");
  }

  const meta = () => {
    return {
      title: 'LPI System For Learn ,Practice,  Improve',
      description: 'LPI System For Learn ,Practice,  Improve',
      canonical: 'http://lpi-tuition.com/',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Learn,Practice,Improve'
        }
      }
    }
  }

  return (
    <>
    <DocumentMeta {...meta}>

      <Header classlist={classSubject} />
      <div id="home" className="pg-tp-m">

        <section id="hero">
          <div className="slider-content">
            <div className="container">
              <h2 className="animate__animated fanimate__adeInDown mb-3">Welcome to <span>LPI Tuition</span></h2>
              {/* <p className="animate__animated animate__fadeInUp mb-3 pe-lg-5 ps-lg-5">A family-run tuition centre dedicated to making learning enjoyable for your child.</p> */}
              <a href="javascript:void(0)" className="video-btns" onClick={showVideo} >Click to watch our video</a>
            </div>
          </div>
          <div>
            <div>
              <div>
                <ReactBootstrapCarousel className="carousel-fade" version={4} autoplay={true} slideshowSpeed={5000} >

                  <div className="carousel-content">
                    <img className="img-fluid" src="assets/img/image22.png" alt="" />
                  </div>

                  <div className="carousel-content box2-img">
                    <img className="img-fluid" src="assets/img/image21.png" alt="" />
                  </div>

                  <div className="carousel-content">
                    <img className="img-fluid" src="assets/img/banner4.jpg" alt="" />
                  </div>

                  <div className="carousel-content">
                    <img className="img-fluid" src="assets/img/banner3.jpg" alt="" />
                  </div>

                  <div className="carousel-content">
                    <img className="img-fluid" src="assets/img/banner2.jpg" alt="" />
                  </div>

                  <div className="carousel-content">
                    <img className="img-fluid" src="assets/img/banner5.jpg" alt="" />
                  </div>
                  
                  <div className="carousel-content">
                    <img className="img-fluid" src="assets/img/banner6.jpg" alt="" />
                  </div>
                </ReactBootstrapCarousel>
                
              </div>
            </div>
          </div>
        </section>
      </div>
      <main id="main">
        <section id="featured" className="featured pb-sec mpt">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-lg-12 col-xl-11">
                <div className="row justify-content-center">
                  <div className="col-sm-4 col-lg-3 mb-3 mb-sm-0">
                    <div className="icon-box learn-box">
                      <div className="hover-box p-3" >
                        <h2>Learn</h2>
                        <p className="mb-2">
                          Students learn the skills required for each topic in the classroom through our unique teaching methods.
                        </p>
                      </div>
                      <div className="ib-icon mb-2 p-3 p-xl-4">
                        <img className="img-fluid" src="assets/img/learn-icon.svg" alt="" />
                      </div>
                      <h3 className="text-center mb-2"><a href="">Learn</a></h3>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-3 mb-3 mb-sm-0">
                    <div className="icon-box practice-box">
                      <div className="hover-box p-3">
                        <h2>Practise</h2>
                        <p className="mb-2">
                          Topics are practised through our unrivalled online system where no homework is ever duplicated.
                        </p>
                      </div>
                      <div className="ib-icon mb-2 p-3 p-xl-4">
                        <img className="img-fluid" src="assets/img/practice-icon.svg" alt="" />
                      </div>
                      <h3 className="text-center mb-2"><a href="">Practise</a></h3>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-3 mb-3 mb-sm-0">
                    <div className="icon-box improve-box">
                      <div className="hover-box p-3">
                        <h2>Improve</h2>
                        <p className="mb-2">
                          Once the syllabus has been taught, students are provided with higher level questions to enhance their understanding and ability.
                        </p>
                      </div>
                      <div className="ib-icon mb-2 p-3 p-xl-4">
                        <img src="assets/img/improve-icon.svg" alt="" />
                      </div>
                      <h3 className="text-center mb-2"><a href="">Improve</a></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about pb-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>{aboutUsTitle}</h2>
                  {ReactHtmlParser(aboutUsHead)}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 content">
                <div className="collapse" id="aboutexp">
                  {ReactHtmlParser(aboutUs)}
                </div>
                <a className="org-btn expend-btn btnhndletrue" data-pos="about" data-bs-toggle="collapse" 
                 href="#aboutexp" role="button" aria-expanded="false" aria-controls="aboutexp">
                  <span>Read more...</span>
                  <span className="btnhndletrue">Read less...</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="plus11" className="plus11 pt-sec pb-sec light-green-bg brdr-tp brdr-btm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>{elevenPlusTitle}</h2>
                  <p>
                    {ReactHtmlParser(elevenPlusHead)}
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-12 content">

                <div className="collapse" id="plus11exp">
                  <div className="mb-3">
                    {ReactHtmlParser(elevenPlus)}
                  </div>
                </div>
                <a className="org-btn expend-btn btnhndletrue" data-pos="plus11" data-bs-toggle="collapse" href="#plus11exp" role="button"
                  aria-expanded="false" aria-controls="plus11exp">
                  <span>Read more...</span>
                  <span data-show={false} className="btnhndletrue">Read less...</span>
                </a>
              </div>
            </div>

          </div>
        </section>
        {classSubject && classSubject.length > 0 ?
          <React.Fragment>
            {classSubject.map((item, index) => {
              return (
                <section id={(index === 0) ? "ks3" : "gcse"}
                  className={(index === 0 || index === 2) ? "ks3 pt-sec pb-sec gen-bg " : "ks3 pt-sec pb-sec light-green-bg brdr-tp brdr-btm"}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="section-title">
                          <h2>{item.title}</h2>
                          <p>{ReactHtmlParser(item.sortDescription)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 content text-center">
                        <div className="row">
                          <div className="col-xl-10 mb-3">
                            <div className="row ">

                              {item.subjectList.map((subject, key) => {
                                return (
                                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mb-3">
                                    <div className="icon-box subject-box-home" style={{ backgroundColor: subject.subjectColour }}>
                                      <div className="hover-box align-items-center justify-content-center  p-4" style={{ backgroundColor: subject.subjectColour }}>
                                        <h2 className={"subject-title-" + subject._id}>{subject.subjectName}</h2>
                                        <div className="mb-2 disc handlecontentbox" id={"descBox_" + subject._id}
                                        >

                                          {ReactHtmlParser(subject.sortDescription)}
                                        </div>

                                        <div
                                          data-subjectId={subject._id}
                                          onClick={updateModelContent}
                                        >
                                          <a className="org-btn expend-btn" data-subjectId={subject._id}
                                            onClick={updateModelContent} data-bs-toggle="collapse"
                                             href="#biology" role="button" aria-expanded="false" 
                                             aria-controls="plus11exp">
                                            <span data-subjectId={subject._id}
                                              onClick={updateModelContent} >Read more...</span>
                                            <span className="inner-hide">Read less...</span>
                                          </a>
                                        </div>
                                        {/* <a className="org-btn expend-btn btnhndletrue" data-bs-toggle="collapse" href="#biology" role="button" aria-expanded="false" aria-controls="plus11exp">
                                        <span>Read more...</span>
                                        <span>Read less...</span>
                                    </a> */}
                                      </div>
                                      {/* <div className="subject-pic mb-3">
                                        <img src={`/assets/img/${subject.subjectName.trim().toLowerCase().replace(" ", "-")}-pic.png`} alt="" className="img-fluid" />
                                      </div> */}

                                      <div className="subject-pic mb-3">
                                        <img src={subject.thumbnailImage} alt="" className="img-fluid" />
                                      </div>
                                      <h4 className="mb-3" ><a href="">{subject.subjectName}</a></h4>
                                    </div>
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            })
            }
          </React.Fragment>
          : ""}
        <section id="workshops" className="about  gcse pt-sec pb-sec gen-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>{workShopTitle}</h2>
                  {ReactHtmlParser(workShopHead)}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 content">
                <div className="collapse" id="workshopexp">

                  <div className="mb-3">
                    {ReactHtmlParser(workShop)}

                  </div>
                  {/*<ul className="">
                        <li><i className="far fa-check-circle"></i> Ullamco laboris nisi ut aliquipex ea commodo consequat.</li>
                        <li><i className="far fa-check-circle"></i> Duis aute irure dolor reprehenderit in voluptate velit.</li>
                        <li><i className="far fa-check-circle"></i> Laboris nisi ut aliquip commodo consequat.</li>
                        <li><i className="far fa-check-circle"></i> Nisi ut aliquip consequat.</li>
                      </ul>*/}
                </div>
                <a className="org-btn expend-btn btnhndletrue" data-pos="workshops" data-bs-toggle="collapse" href="#workshopexp" role="button" aria-expanded="false" aria-controls="workshopexp">
                  <span>Read more...</span>
                  <span data-show={false} className="btnhndletrue">Read less...</span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" className="about gcse pt-sec pb-sec light-green-bg  brdr-tp brdr-btm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>{pricingTitle}</h2>
                  {ReactHtmlParser(pricingHead)}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 content">
                <div className="collapse" id="pricingexp">

                  <div className="mb-3">
                    {ReactHtmlParser(pricing)}
                  </div>
                </div>
                <a className="org-btn expend-btn btnhndletrue" data-pos="pricing" data-bs-toggle="collapse" href="#pricingexp" role="button" aria-expanded="false" aria-controls="pricingexp">
                  <span>Read more...</span>
                  <span data-show={false} className="btnhndletrue">Read less...</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="about  gcse pt-sec pb-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title" id="faqSectionHome">
                  <h2>FAQ</h2>
                  {/* <p className="mb-3">
                          Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </p>  */}

                  {faqListData && faqListData.length > 0 ?
                    <React.Fragment>
                      <Accordion defaultActiveKey={['0']} alwaysOpen>
                        {faqListData.map((item, index) => {
                          return (index < 3) ?
                            <Accordion.Item eventKey={index} >
                              <Accordion.Header className="aa">Q. {item.title} </Accordion.Header>
                              <Accordion.Body className="inner-body-faq">
                                <div className="answerboxes"> <strong>A.</strong> &nbsp;<div> {ReactHtmlParser(item.faqContent)} </div></div>
                              </Accordion.Body>
                            </Accordion.Item>
                            :

                            <Accordion.Item eventKey={index} className="inner-hide faqContentBox" >
                              <Accordion.Header className="aa">Q. {item.title} </Accordion.Header>
                              <Accordion.Body className="inner-body-faq">
                                <div className="answerboxes"> <strong>A.</strong> &nbsp;<div> {ReactHtmlParser(item.faqContent)}</div> </div>
                              </Accordion.Body>
                            </Accordion.Item>

                        })
                        }
                      </Accordion>
                    </React.Fragment>
                    : ""}

                  {(faqListData.length > 3) ?
                    <a className="org-btn expend-btn showFAQList" data-pos="faq" style={{ marginTop: "15px" }} role="button" aria-expanded="false" >
                      Show more...
                      {/*
                              <span className="showFAQList">Show more...</span>
                              <span data-show={false}  className="hideFAQList">Show less...</span>
                            */}
                    </a>
                    : ""
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonial" className="pt-sec pb-sec light-green-bg brdr-tp brdr-btm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>Testimonials</h2>

                </div>
              </div>
            </div>

            <div className="row" style={{ display: "flex" }}>
              <div className="col-lg-12 content">
                <ReactBootstrapCarousel
                  indicators={true}
                  className="carousel-slide"
                  version={4}
                  autoplay={false}
                  slideshowSpeed={2000}
                  onSelect={handleSelect}
                  fade={true} >
                  {ReactHtmlParser(rowTestData)}
                </ReactBootstrapCarousel>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="contact  pt-sec pb-sec gen-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>Contact us</h2>
                  {/* <p>
                          Lorem, ipsum dolor sit amet, consectetur adipisicing elit. Magni, inventore.
                        </p> */}
                </div>
              </div>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="fas fa-location-arrow"></i>
                  <h3>Our Address</h3>
                  <p>1 Kings Court, Newcomen Way, Colchester, CO4 9RA</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="fas fa-envelope"></i>
                  <h3>Email Us</h3>
                  <p><a href="mailto:enquire.lpi@gmail.com">enquire.lpi@gmail.com</a></p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="fas fa-phone-square-alt"></i>
                  <h3>Call Us</h3>
                  <p><a href="tel:07786885114">07786885114</a></p>
                </div>
              </div>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">
                <div className="map-sec">
                  
                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2460.6292743659696!2d0.918958516114148!3d51.92247457970539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d9056d15331719%3A0x58c66778094a8f65!2sLPI%20Tuition!5e0!3m2!1sen!2sin!4v1674144738782!5m2!1sen!2sin" width="600" height="450" className="mb-4 mb-lg-0"  style={{ width: '100%', height: '344px' }}  ></iframe> 
                </div>
              </div>
              
              <div className="col-lg-6">
                <ToastContainer />
                <form onSubmit={handleSubmit} method="post" role="form" className="php-email-form" id="contactForm">
                  <div className="row">
                    <div className="col form-group">
                      <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={handleName} />
                      <div>{nameError && <span className="invalid-feedback">{nameError}</span>}</div>
                    </div>
                    <div className="col form-group">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" autoComplete="nope" onKeyUp={handleEmail} />
                      <div>{emailError && <span className="invalid-feedback">{emailError}</span>}</div>
                    </div>
                  </div>
                  
                  <div className="form-group">
                    <input type="text" className="form-control" name="contact" id="contact" placeholder="Contact Number" onChange={handleContactNumber} min="0" />
                    <div>{contactNumberError && <span className="invalid-feedback">{contactNumberError}</span>}</div>

                  </div>

                  <div className="form-group">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleSubject} />
                    <div>{subjectError && <span className="invalid-feedback">{subjectError}</span>}</div>

                  </div>
                 
                  <div className="form-group">
                    <textarea className="form-control" name="message" rows="5" placeholder="Message" onChange={handleMessage}></textarea>
                    <div>{messageError && <span className="invalid-feedback">{messageError}</span>}</div>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                  </div>
                  <div className="text-center">
                    <button type="submit" >Send Message</button> &nbsp; &nbsp;
                    {/* <a href="https://calendly.com/lpi-tuition" target="_blank" className="book-app ms-xs-0 ms-sm-4 ms-md-4 ms-lg-4">Book an appointment</a> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Button variant="primary" className="showmodeldes" style={{ display: "none" }} onClick={handleShow} 
          backdrop="static"
          keyboard={false}>
          Launch static backdrop modal
        </Button>

      </main>
      <Modal show={showDes} size="lg" >
        <Modal.Header>
          <Modal.Title style={{ margin: "0 auto" }}> Testimonials Description</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <h6 style={{fontSize:"16px"}}><strong>Parent Name: </strong><strong className="pnamet"></strong> </h6>
          <h6 style={{fontSize:"16px"}}><strong>Child Name: </strong><strong className="cnamet"></strong> </h6>
          <h6 style={{fontSize:"16px"}}><strong>Course: </strong><strong className="classnamet"></strong> </h6>

          <div  style={{fontSize:"16px",textAlign:"justify"}}className="showTestDes"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer show={show}  showVideoStatus={showVideoStatus} onHandleModelCloseVideo={hideVideoBox} onHandleModelClose={hideModelBox} subjectDescription={modelSubjectDescription} subjectTitle={modelSubjectTitle} />
      </DocumentMeta>

    </>
  );
}
export default Home;
